import React, { useState } from 'react';
import { Drawer } from 'antd';
import Icon from '../utils/Icon';
import SocialButtons from '../buttons/SocialButtons';
import HeaderLanguage from './HeaderLanguage';
import RadarLogo from '../RadarLogo';
import HeaderThemeSwitch from './HeaderThemeSwitch';

const HeaderMenu = () => {
  const [menuState, setMenuState] = useState(false);
  const handleMenu = () => {
    setMenuState((prev) => !prev);
  };
  return (
    <div className="header__menu">
      <button
        className="header__menu-button"
        type="button"
        onClick={handleMenu}
      >
        <Icon name="burger" />
      </button>
      <Drawer
        title={null}
        onClose={handleMenu}
        open={menuState}
        extra={
          <div className="header__drawer-head">
            <RadarLogo width={100} />
            <div className="header__drawer-right">
              <button
                type="button"
                onClick={handleMenu}
                className="header__drawer-close"
              >
                <Icon name="close" />
              </button>
            </div>
          </div>
        }
      >
        <div className="header__drawer-content">
          <div className="header__drawer-top">
            {/* <HeaderAuth /> */}
            {/* <HeaderSearch /> */}
            {/* <HeaderNavigation /> */}
          </div>
          <div className="header__drawer-top--functional">
            <HeaderLanguage />
            <HeaderThemeSwitch />
          </div>
          <div className="header__drawer-social">
            <SocialButtons />
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default HeaderMenu;

import React from 'react';
import SocialButtons from '../buttons/SocialButtons';
import Divider from '../Divider';

const FooterCopyright = () => {
  return (
    <div className="footer__copyright">
      <Divider />
      <div className="footer__copyright-info">
        <div className="footer__copyright-text">
          ©2024 Radar. All rights reserved.
        </div>
        <SocialButtons
          facebook="https://www.facebook.com/profile.php?id=61556985007843"
          instagram="https://www.instagram.com/radarclubcomunitate?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
          linkedin="https://www.linkedin.com/company/expert-insight-exchange"
        />
      </div>
    </div>
  );
};

export default FooterCopyright;

import React from 'react';
import { Empty } from 'antd';
import useDatasource from '../../hooks/useDatasource';
import { findAll } from '../../servicies/mediafiles/magazine';
import MagazinePreview from './MagazinePreview';

const MagazinesListView = ({ onSelect = () => {} }) => {
  const { loading, pagination, content } = useDatasource(findAll);

  // TODO: all from console.log should be used for a better page
  console.log(loading, pagination);

  // TODO: ADD some kind of loader and pagination

  return content.length ? (
    <div className="magazine__list">
      {content?.map((el) => (
        <MagazinePreview
          key={el?.id}
          // name={el.title}
          imageId={el?.imageId}
          onClick={() => onSelect(el)}
        />
      ))}
    </div>
  ) : (
    <Empty />
  );
};

export default MagazinesListView;

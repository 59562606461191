import React from 'react';

const Loader = ({ loading }) => {
  return (
    <div>
      {loading ? (
        <div className="loader">
          <div className="razar">
            <div className="ringbase ring1" />
            <div className="ringbase ring2" />
            <div className="pulse" />
            <div className="pointer">
              <div />
            </div>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((dot) => (
              <div
                style={{
                  animationDelay: `0.${dot}s`,
                }}
                key={dot}
                className={`dot pos${dot}`}
              />
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Loader;

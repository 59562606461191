import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { notification } from 'antd';

export function useEdit({
  initial,
  dependency = null, // will trigger findOne function on change
  existent,
  onInvalid, // or handleCancel will be called
  onFailed,
  onCreate,
  onUpdate,
  id,
  isNew,
}) {
  const { t } = useTranslation();
  const [entity, setEntity] = useState(undefined);
  const [errors, setErrors] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleFail = useCallback(
    (err) => {
      notification.error({
        message: t('errors.client.loadFailed', err),
      });
    },
    [t],
  );

  useEffect(() => {
    (isNew ? Promise.resolve(initial) : existent(id))
      .then((res) => setEntity(res))
      .catch((err) => {
        if (typeof onFailed === 'function') {
          onFailed(err);
        } else {
          handleFail(err);
        }
      });
  }, [
    id,
    isNew,
    dependency,
    initial,
    existent,
    onInvalid,
    onFailed,
    handleFail,
  ]);

  const reload = useCallback(() => {
    if (id) {
      existent(id)
        .then((res) => setEntity(res))
        .catch((err) => {
          if (typeof onFailed === 'function') {
            onFailed(err);
          } else {
            handleFail(err);
          }
        });
    }
  }, [id, existent, handleFail, onFailed]);

  const handleSubmit = useCallback(
    (value) => {
      setErrors(null);
      setLoading(true);
      return (isNew ? onCreate(value) : onUpdate({ ...value, id }))
        .then((res) => {
          notification.success({
            message: t('actions.saveSuccessful'),
            duration: 3,
          });

          if (res?.id) {
            setEntity(res);
          } else if (!isNew) {
            reload();
          }
        })
        .catch((msg) => {
          const { inner } = msg || {};
          const { _: messageCode } = inner || {};

          setErrors(inner);

          notification.error({
            message: messageCode || t('errors.client.saveFailed'),
          });

          return Promise.reject(msg);
        })
        .finally(() => setLoading(false));
    },
    [reload, id, isNew, onCreate, onUpdate, t],
  );

  const result = useMemo(
    () => [entity, handleSubmit, errors, loading, reload],
    [entity, handleSubmit, errors, loading, reload],
  );

  return result;
}

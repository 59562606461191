import React, { useMemo } from 'react';
import { Route, Routes } from 'react-router-dom';
import { AuthData, hasPermission, isPublicRoute } from '../auth/AuthWrapper';
import { NAV } from './nav-locations';
import { Forbidden } from '../../pages';

const RouteRenderer = () => {
  const { userContext } = AuthData();
  const { loaded, user } = userContext;

  const routes = useMemo(
    () =>
      loaded &&
      NAV.map((route) => {
        if (isPublicRoute(route?.authorities || [])) {
          return (
            <Route key="public" path={route.path} element={route.element} />
          );
        }
        if (hasPermission(user?.authorities, route.authorities)) {
          return (
            <Route
              key="hasPermission"
              path={route.path}
              element={route.element}
            />
          );
        }
        return (
          <Route key="forbidden" path={route.path} element={<Forbidden />} />
        );
      }),
    [loaded, user],
  );

  return <Routes>{routes}</Routes>;
};

export default RouteRenderer;

import React from 'react';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import Icon from '../utils/Icon';

const AddEntityButton = ({
  title,
  path,
  entity,
  onClick,
  disabled,
  ...styles
}) => {
  const navigate = useNavigate();

  return (
    <Button
      type="primary"
      disabled={disabled}
      icon={<Icon name="plus" />}
      onClick={() =>
        !path && typeof onClick === 'function' ? onClick() : navigate(path)
      }
      {...styles}
    >
      {title} {entity}
    </Button>
  );
};
export default AddEntityButton;

import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, notification } from 'antd';
import { getCurrentUserDetails, logout } from '../../servicies/users/auth';
import { useTranslate } from '../../hooks/useTranslate';

export const PUBLIC_PERMS = Object.freeze({
  AUTHENTICATED: 'AUTHENTICATED',
  PUBLIC_TAXONOMY_SEARCH: 'PUBLIC_TAXONOMY_SEARCH',
});

export const ADMIN_PERMS = Object.freeze({
  ADMIN: 'ADMIN',
  ADMIN_FILE_MANAGEMENT: 'ADMIN_FILE_MANAGEMENT',
  ADMIN_MANAGE_MAGAZINE: 'ADMIN_MANAGE_MAGAZINE',
  ADMIN_TAXONOMY_MANAGEMENT: 'ADMIN_TAXONOMY_MANAGEMENT',
  ADMIN_ROLES_MANAGEMENT: 'ADMIN_ROLES_MANAGEMENT',
  ADMIN_USERS_MANAGEMENT: 'ADMIN_USERS_MANAGEMENT',
});

const AuthContext = createContext();
export const AuthData = () => useContext(AuthContext);

const AuthWrapper = ({ children }) => {
  const { translate } = useTranslate('warnings');
  const { pathname } = useLocation();
  const [loaded, setLoaded] = useState(true);
  const [user, setUser] = useState({ name: '', authorities: [] });
  const navigate = useNavigate();

  const description = useMemo(() => {
    return (
      <>
        {translate('account-not-confirmed-description')}
        <Button onClick={() => navigate(`personal/user/${user?.id}`)}>
          {translate('openSignUpBtn')}
        </Button>
      </>
    );
  }, [navigate, translate, user?.id]);

  useEffect(() => {
    if (pathname && user?.authorities?.length > 0) {
      setLoaded(false);
      getCurrentUserDetails()
        .then((res) => {
          if (!res.isConfirmed) {
            notification.destroy();
            notification.warning({
              message: translate('account-not-confirmed'),
              description,
              duration: 0,
            });
          }
          setUser({
            ...res,
            authorities: [
              ...(res?.permissions || []),
              PUBLIC_PERMS.AUTHENTICATED,
            ],
          });
        })
        .catch(() => {
          setUser(null);
        })
        .finally(() => setLoaded(true));
    }
    // TODO: check for optimisation in case of large amount of users
    // TODO: call every half of active hour?
    // eslint-disable-next-line
  }, []);

  const userContext = useMemo(
    () => ({ user, loaded, logout, setUser }),
    [user, loaded],
  );

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <AuthContext.Provider value={{ userContext }}>
      {loaded && children}
    </AuthContext.Provider>
  );
};

const arrayIsEmpty = (arr) =>
  arr ? Array.isArray(arr) && arr.length === 0 : true;

export const isPublicRoute = (routePerms) => arrayIsEmpty(routePerms);

export const hasPermission = (userPerm, allowedPerm) => {
  let allowed = allowedPerm;
  if (typeof allowedPerm === 'string') {
    allowed = [allowedPerm];
  }

  if (arrayIsEmpty(allowed)) {
    return true;
  }

  if (arrayIsEmpty(userPerm)) {
    return false;
  }

  for (let i = 0; i < allowed.length; i += 1) {
    if (userPerm.includes(allowed[i])) {
      return true;
    }
  }
  return false;
};

export default AuthWrapper;

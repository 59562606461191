import React, { useMemo } from 'react';
import { Table } from 'antd';
import Column from '../../utils/Columns';
import EditItemIcon from '../../buttons/icons/EditItemIcon';
import DeleteItemIcon from '../../buttons/icons/DeleteItemIcon';
import { findAll, remove } from '../../../servicies/mediafiles/magazine';
import { useTranslate } from '../../../hooks/useTranslate';
import useDatasource from '../../../hooks/useDatasource';

const MagazineTable = ({ handleSelect }) => {
  const { t, translate } = useTranslate('pages.admin.magazine.table');
  const { loading, pagination, content, handleChange, reload } =
    useDatasource(findAll);
  const columns = useMemo(
    () => [
      Column.text('id', translate('id'), {
        width: 50,
        filter: true,
      }),
      Column.text('title', translate('title'), {
        width: 50,
        filter: true,
      }),
      Column.other(
        'tags',
        translate('tags'),
        (code, row) => {
          return (
            <div className="magazine__table-tags">
              {row.tags.map((tag) => {
                return <span key={tag}>{tag}</span>;
              })}
            </div>
          );
        },
        {
          width: 50,
          filter: true,
        },
      ),
      Column.text('createdBy', translate('createdBy'), {
        width: 50,
      }),
      Column.date('createdAt', translate('createdAt'), {
        width: 50,
      }),
      Column.text('updatedBy', translate('updatedBy'), {
        width: 50,
      }),
      Column.date('updatedAt', translate('updatedAt'), {
        width: 50,
      }),
      Column.actions(t, (record) => (
        <span style={{ textAlign: 'right' }}>
          <EditItemIcon
            key={`edit${record.id}`}
            onClick={() => handleSelect(record.id)}
          />
          <DeleteItemIcon
            key={`delete${record.id}`}
            title={translate('_delete', record)}
            message={translate('_deleted', record)}
            item={record}
            onClick={remove}
            reload={reload}
            isPromise
          />
        </span>
      )),
    ],
    [reload, t, translate, handleSelect],
  );
  return (
    <Table
      loading={loading}
      pagination={pagination}
      dataSource={content}
      onChange={handleChange}
      columns={columns}
    />
  );
};

export default MagazineTable;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/images/logo.png';

const RadarLogo = ({ width = 150 }) => {
  const navigate = useNavigate();

  return (
    <button onClick={() => navigate('/')} type="button">
      <img className="header__logo" src={logo} alt="logo" style={{ width }} />
    </button>
  );
};

export default RadarLogo;

export const PUBLIC_ENV = 'http://86.105.81.249:18000';

// window._env_.API_BACKEND_URL
export const handleHttpErrors = (response) => {
  if (!response.ok) {
    switch (response.status) {
      case 401:
      case 403:
        window.location.href = '/';
        return Promise.reject(response);
      case 400:
      case 409:
        return response.json().then((envelope) =>
          // eslint-disable-next-line prefer-promise-reject-errors
          Promise.reject({ inner: envelope.payload || envelope }),
        );
      default:
        return Promise.reject(response);
    }
  }
  return response;
};

export const get = async (endpoint, options = {}) =>
  fetch(`${PUBLIC_ENV}${endpoint}`, {
    method: 'GET',
    mode: 'cors',
    credentials: 'include',
    ...options,
    headers: {
      'Content-Type': 'application/json',
      ...(options.headers || {}),
    },
  })
    .then(handleHttpErrors)
    .then((resp) => resp.json());

export const post = async (endpoint, data, options = {}) => {
  const { _pintoken, ...rest } = data || {};

  return fetch(`${PUBLIC_ENV}${endpoint}`, {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: rest == null || Object.keys(rest) === 0 ? '' : JSON.stringify(rest),
    ...options,
  })
    .then(handleHttpErrors)
    .then((resp) => {
      const contentType = resp.headers.get('content-type');
      return contentType && contentType.indexOf('application/json') !== -1
        ? resp.json()
        : resp.text();
    });
};

export const put = async (endpoint, data, options = {}) => {
  return fetch(`${PUBLIC_ENV}${endpoint}`, {
    method: 'PUT',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
    ...options,
  })
    .then(handleHttpErrors)
    .then((resp) => {
      const contentType = resp.headers.get('content-type');
      return contentType && contentType.indexOf('application/json') !== -1
        ? resp.json()
        : resp.text();
    });
};

export const remove = async (endpoint, options = {}) =>
  fetch(`${PUBLIC_ENV}${endpoint}`, {
    method: 'DELETE',
    credentials: 'include',
    ...options,
    headers: {
      'Content-Type': 'application/json',
      ...(options.headers || {}),
    },
  }).then(handleHttpErrors);

const flatObject = (object, target = {}, prev = '') => {
  Object.entries(object).forEach(([k, v]) => {
    const newKey = `${prev}.${k}`;
    if (typeof v === 'object' && v !== null) {
      flatObject(v, target, newKey);
    } else {
      // eslint-disable-next-line no-param-reassign
      target[newKey] = v;
    }
  });
  return target;
};

const pairToString = (key, value) => {
  if (Array.isArray(value)) {
    return value.map((subvalue) => pairToString(key, subvalue)).join('&');
  }

  if (typeof value === 'object' && value !== null) {
    return Object.entries(flatObject(value))
      .map(([flattenKey, subvalue]) =>
        pairToString(`${key}${flattenKey}`, subvalue),
      )
      .join('&');
  }

  return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
};
const getSearchQueryString = (data) =>
  [
    ...Object.entries(data?.criterias || {}).map(([key, value]) =>
      pairToString(key, value),
    ),
    ...[data?.pageNumber ? `page=${data.pageNumber - 1}` : null],
    ...[data?.pageSize ? `size=${data.pageSize}` : null],
    ...[
      data?.sort
        ? `sort=${encodeURIComponent(`${data.sort[0]},${data.sort[1]}`)}`
        : null,
    ],
  ]
    .filter((val) => val != null)
    .join('&');

export const search = async (endpoint, data, options = {}) =>
  fetch(
    `${PUBLIC_ENV}${endpoint}${
      endpoint.endsWith('&') ? '' : '?'
    }${getSearchQueryString(data)}`,
    {
      method: 'GET',
      credentials: 'include',
      ...options,
      headers: {
        'Content-Type': 'application/json',
        ...(options.headers || {}),
      },
    },
  )
    .then(handleHttpErrors)
    .then((resp) => resp.json());

export const genericService = (serviceUri, baseUri) => {
  const uri = `${serviceUri}${baseUri}`;

  return {
    findAll: (data) => search(uri, data),
    findOne: (id) => get(`${uri}/${id}`),
    create: (entity) => post(`${uri}`, entity),
    update: (entity) => put(`${uri}/${entity.id}`, entity),
    remove: (entity) => remove(`${uri}/${entity.id}`),
  };
};

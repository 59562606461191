import React, {
  useState,
  useCallback,
  useRef,
  useEffect,
  useMemo,
} from 'react';
import { useDropzone } from 'react-dropzone';
import Cropper from 'react-cropper';
import { Image, Modal, notification } from 'antd';
import imageCompression from 'browser-image-compression';
import Icon from './Icon';
import 'cropperjs/dist/cropper.min.css';
import { useTranslate } from '../../hooks/useTranslate';

const ImageUploader = ({
  initial,
  onImageClear = () => {},
  onImageUpload,
  disabled = false,
  options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 900,
    useWebWorker: true,
  },
  crop = true,
  radius = '10px',
  aspectRatio = 9 / 9,
  size = 200,
}) => {
  const cropperRef = useRef(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [cropModalVisible, setCropperModalVisible] = useState(false);
  const { translate } = useTranslate('components.files');

  useEffect(() => {
    setImageUrl(initial);
  }, [initial]);

  const limitFileSize = parseInt(window._env_.FILE_SIZE_LIMIT_BYTES, 10);

  const clearImage = useCallback(() => {
    if (imageUrl) {
      setImageUrl(null);
      URL.revokeObjectURL(imageUrl);
    }
    onImageClear();
  }, [imageUrl, onImageClear]);

  const saveImage = useCallback(
    (compresed) => {
      if (compresed) {
        const reader = new FileReader();
        reader.readAsDataURL(compresed);
        reader.onload = () => {
          const base64String = reader.result.split(',')[1];
          try {
            const arrayBuffer = Uint8Array.from(atob(base64String), (c) =>
              c.charCodeAt(0),
            ).buffer;
            const blob = new Blob([arrayBuffer]);
            const objectUrl = URL.createObjectURL(blob);
            setImageUrl(objectUrl);
            setCropperModalVisible(true);
          } catch (e) {
            console.error(
              "Couldn't decode image base64, string not correctly encoded",
            );
            setImageUrl(null);
          }
          if (!crop && onImageUpload && typeof onImageUpload === 'function') {
            onImageUpload(base64String);
          }
        };
      }
    },
    [onImageUpload, crop],
  );

  const { getInputProps, open } = useDropzone({
    onDrop: (files) => {
      if (disabled) {
        return;
      }
      const file = files[0];
      imageCompression(file, options)
        .then((compressedFile) => {
          return saveImage(compressedFile);
        })
        .catch(() => {
          console.error('Image could not uploaded.');
        });
    },

    validator: (file) => {
      if (file.size > limitFileSize) {
        notification.error(
          ('file.size',
          {
            fileName: file.name,
            limitSize: Math.ceil(limitFileSize / 1024 ** 2),
          }),
        );
      }
    },
    accept: { 'image/*': [] },
    maxSize: limitFileSize,
    multiple: false,
  });

  const handleCropAndUpload = () => {
    if (cropperRef.current) {
      const { cropper } = cropperRef.current;
      const croppedCanvas = cropper.getCroppedCanvas();

      if (croppedCanvas) {
        const dataURL = croppedCanvas.toDataURL();
        setImageUrl(dataURL);
        if (onImageUpload && typeof onImageUpload) {
          onImageUpload(dataURL.split(',')[1]);
        }
        setCropperModalVisible(false);
      }
    }
  };

  const style = useMemo(
    () => ({
      borderRadius: aspectRatio === 1 ? '50%' : radius,
      width: `${size}px`,
      height: `${size / aspectRatio}px`,
      minWidth: `${size}px`,
    }),
    [aspectRatio, radius, size],
  );

  return (
    <div className="settings__uploader" style={style}>
      <div className={`settings__image ${imageUrl ? 'active' : ''}`}>
        <Image
          src={imageUrl}
          preview={false}
          style={{
            objectFit: 'cover',
            objectPosition: 'center',
            ...style,
          }}
        />
        {imageUrl ? (
          <div className="settings__image-overlay" style={style}>
            <button
              type="button"
              className="settings__image-delete"
              onClick={clearImage}
            >
              <Icon name="delete" />
            </button>
          </div>
        ) : (
          <button
            type="button"
            className="settings__image-upload"
            onClick={open}
            style={style}
          >
            <input {...getInputProps()} />
            <div className="settings__upload-icon">
              <Icon name="plus" />
              <div>{translate('upload')}</div>
            </div>
          </button>
        )}
      </div>
      {crop ? (
        <Modal
          className="modal__crop"
          open={cropModalVisible}
          onOk={handleCropAndUpload}
          width={800}
          onCancel={() => setCropperModalVisible(false)}
          closeIcon={<Icon name="close" />}
          centered
        >
          <Cropper
            ref={cropperRef}
            src={imageUrl}
            guides
            aspectRatio={aspectRatio}
          />
        </Modal>
      ) : null}
    </div>
  );
};

export default ImageUploader;

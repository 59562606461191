import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

export function useTranslate(prefix) {
  const { t, i18n } = useTranslation();

  const translate = useCallback(
    (key, obj = undefined) => {
      if (i18n.isInitialized) {
        return t(prefix ? `${prefix}.${key}` : key, obj);
      }
      return '';
    },
    [t, prefix, i18n],
  );

  return { translate, t };
}

import { post, PUBLIC_ENV, put } from '../api';

export const SERVICE_URI = '/auth';
export const BASE_URI = '/api/v1';

// window._env_.API_BACKEND_URL

export const getCurrentUserDetails = async () =>
  fetch(`${PUBLIC_ENV}${SERVICE_URI}${BASE_URI}/current`, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((resp) => (resp.ok ? resp.json() : Promise.reject(resp)));

export const refreshToken = async () =>
  fetch(`${PUBLIC_ENV}${SERVICE_URI}${BASE_URI}/v1/local/refresh-token`, {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
  }).then((resp) => (resp.ok ? Promise.resolve() : Promise.reject(resp)));

export const logout = () => {
  window.location.href = `${PUBLIC_ENV}${SERVICE_URI}${BASE_URI}/logout`;
};

export const signUp = (payload) =>
  post(`${SERVICE_URI}/v1/local/signup`, payload);

export const validate = (code) =>
  put(`${SERVICE_URI}${BASE_URI}/validate/${code}`);

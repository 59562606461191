import React, { useMemo } from 'react';
import { DatePicker, Form } from 'antd';
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_TIME_FORMAT,
} from '../../utils/defaults/dates';
import Icon from '../utils/Icon';

const FormDatePicker = ({
  name,
  label,
  translate,
  format,
  type,
  disabled,
  labelCol = 24,
  formProps = {},
  pickerProps = {},
}) => {
  const labelText = useMemo(() => {
    const isTranslatable = translate && typeof translate === 'function';
    return isTranslatable ? translate(name) : label;
  }, [label, translate, name]);

  return (
    <Form.Item
      name={name}
      label={labelText}
      labelCol={{ span: labelCol }}
      {...formProps}
    >
      <DatePicker
        disabled={disabled}
        name={name}
        format={
          format || type === 'time' ? DEFAULT_TIME_FORMAT : DEFAULT_DATE_FORMAT
        }
        picker={type}
        suffixIcon={<Icon name="calendar" />}
        {...pickerProps}
      />
    </Form.Item>
  );
};

export default FormDatePicker;

import React, { useMemo } from 'react';
import { Form } from 'antd';
import TextArea from 'antd/es/input/TextArea';

const FormArea = ({
  name,
  label,
  translate,
  disabled,
  labelCol = 24,
  formProps = {},
  areaProps = {},
  showCount = true,
  maxLength = 300,
  rows = 3,
  required,
}) => {
  const labelText = useMemo(() => {
    const isTranslatable = translate && typeof translate === 'function';
    return isTranslatable ? translate(name) : label;
  }, [label, translate, name]);

  return (
    <Form.Item
      name={name}
      label={labelText}
      labelCol={{ span: labelCol }}
      required={required}
      {...formProps}
    >
      <TextArea
        name={name}
        disabled={disabled}
        showCount={showCount}
        maxLength={maxLength}
        rows={rows}
        {...areaProps}
      />
    </Form.Item>
  );
};

export default FormArea;

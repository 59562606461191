import React from 'react';
import { Button } from 'antd';
import Icon from '../utils/Icon';

const MagazineEditHistoryItem = ({
  history,
  handleDelete = () => {},
  onPageClick = () => {},
  modal,
}) => {
  const handleClick = (i) => {
    if (onPageClick && typeof onPageClick === 'function') {
      onPageClick(i);
    }
  };

  return (
    <div className={`magazine__modal-history--items ${!modal ? 'nav' : ''}`}>
      {history?.map((item) => {
        return (
          <button
            onClick={!modal ? () => handleClick(item?.fromPage) : () => {}}
            type="button"
            className={`magazine__modal-history--item ${!modal ? 'nav' : ''}`}
            key={item.id}
          >
            <div>{item.userName}</div>
            <div>
              {item.fromPage} - {item.toPage}
            </div>
            {modal ? (
              <Button
                className="magazine__modal-history--delete"
                type="link"
                icon={<Icon name="delete" />}
                onClick={handleDelete}
              />
            ) : null}
          </button>
        );
      })}
    </div>
  );
};

export default MagazineEditHistoryItem;

import React, { useMemo, useState } from 'react';
import { PageHeader } from '@ant-design/pro-layout';
import NavigableBlock from '../../utils/NavigableBlock';
import { useTranslate } from '../../../hooks/useTranslate';
import Container from '../../../utils/layout/Container';
import Countries from '../../../pages/taxonomies/countries/Countries';
import BackButton from '../../buttons/BackButton';
import Constants from '../../../pages/taxonomies/constants/Constants';

const TaxonomiesList = () => {
  const { translate } = useTranslate('admin.taxonomies');
  const [TaxonomyComponent, setTaxonomyComponent] = useState(null);

  const taxonomiesList = useMemo(() => {
    const list = [
      {
        id: 1,
        icon: 'taxonomies',
        component: <Countries />,
        name: translate('country'),
      },
      {
        id: 2,
        icon: 'taxonomies',
        component: <Constants />,
        name: translate('constant'),
      },
    ];

    list.sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });

    return list;
  }, [translate]);

  return (
    <div className="taxonomies">
      <Container>
        <PageHeader title={translate('title')} />
        {TaxonomyComponent !== null ? (
          <>
            {TaxonomyComponent}
            <BackButton onCancel={() => setTaxonomyComponent(null)} />
          </>
        ) : (
          <div
            className="taxonomies__content"
            style={{ display: 'flex', flexWrap: 'wrap' }}
          >
            {taxonomiesList.map((item) => (
              <NavigableBlock
                {...item}
                key={item.id}
                onClick={() => setTaxonomyComponent(item.component)}
              />
            ))}
          </div>
        )}
      </Container>
    </div>
  );
};

export default TaxonomiesList;

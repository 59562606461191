import React from 'react';
import { Layout } from 'antd';
import Header from './header/Header';
import Footer from './footer/Footer';

const { Content } = Layout;

const Default = ({ children }) => {
  return (
    <Layout>
      <Header />
      <Layout className="default__content">
        <Content>{children}</Content>
      </Layout>
      <Footer />
    </Layout>
  );
};

export default Default;

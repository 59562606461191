import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Tooltip } from 'antd';
import { useTranslate } from '../../../hooks/useTranslate';
import Icon from '../../utils/Icon';

const EditItemIcon = ({
  path,
  onClick = null,
  disabled = false,
  icon = 'edit',
  tooltipTitle = 'edit',
}) => {
  const navigate = useNavigate();
  const { translate } = useTranslate('actions');

  return (
    <Tooltip title={translate(tooltipTitle)}>
      <Button
        shape="circle"
        type="link"
        icon={<Icon name={icon} />}
        onClick={() =>
          onClick && typeof onClick === 'function' ? onClick() : navigate(path)
        }
        disabled={disabled}
      />
    </Tooltip>
  );
};

export default EditItemIcon;

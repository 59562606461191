import React from 'react';
import RadarLogo from '../RadarLogo';
import Icon from '../utils/Icon';

const FooterInfo = () => {
  return (
    <div className="footer__info">
      <RadarLogo width={200} />
      {/* //TODO: content static */}
      <a href="mailto:radarplatform7@gmail.com" className="footer__info-link">
        <Icon name="mail" />
        radarplatform7@gmail.com
      </a>
      {/* //TODO: content static */}
      {/* <a href="tel:1201895321" className="footer__info-link"> */}
      {/*  <Icon name="phone" /> */}
      {/*  +1 (201) 895-321 */}
      {/* </a> */}
      {/* <IconButton title="Get a profession" /> */}
    </div>
  );
};

export default FooterInfo;

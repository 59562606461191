import { get, genericService } from '../api';

export const SERVICE_URI = '/backend';
export const BASE_URI = '/api/v1/magazine';

export const { findAll, findOne, create, update, remove } = genericService(
  SERVICE_URI,
  BASE_URI,
);

export const getPartialBook = (magazineId, startPage, endPage) =>
  get(
    `${SERVICE_URI}${BASE_URI}/partial-book/${magazineId}/${startPage}/${endPage}`,
  );

export const requiredField = (t) => {
  return [
    {
      required: true,
      message: t('form.rules.fieldRequired'),
    },
  ];
};

export const requireEmail = (t, required = true) => {
  return [
    {
      required,
      message: t('form.rules.fieldRequired'),
    },
    {
      type: 'email',
      message: t('form.rules.invalidMailFormat'),
    },
  ];
};

export const passwordRule = (t, required = true) => {
  return [
    {
      required,
      message: t('form.rules.fieldRequired'),
    },
    {
      min: 8,
      max: 30,
      message: t('form.rules.passwordLength'),
    },
    {
      pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/,
      message: t('form.rules.passwordRegex'),
    },
  ];
};
export const tagRules = (t, required = false) => {
  return [
    {
      required,
      message: t('form.rules.fieldRequired'),
    },

    {
      pattern: /^(?!(?:[^ ]* ){5})[^\r\n]*$/,
      message: t('form.rules.tagRegex'),
    },
  ];
};

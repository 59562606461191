import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import Icon from '../utils/Icon';

const translate = {
  _: 'components.buttons.backButton',
};
const BackButton = ({ onCancel = () => {}, buttonText, ...rest }) => {
  const { t } = useTranslation();

  return (
    <Button icon={<Icon name="back" />} onClick={onCancel} {...rest}>
      {buttonText || t(translate._)}
    </Button>
  );
};

export default BackButton;

import React from 'react';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const Forbidden = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const goHome = () => {
    navigate(-1);
  };

  return (
    <div className="forbidden">
      <div className="fb">
        <h1>Error 403</h1>
        <h2>{t('errors.client.forbidden')}</h2>
        <br />
        <Button onClick={goHome}>{t('components.buttons.goHomeBtn')}</Button>
      </div>
    </div>
  );
};

export default Forbidden;

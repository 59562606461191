import React from 'react';
import Container from '../../utils/layout/Container';
import HeaderLanguage from './HeaderLanguage';
import RadarLogo from '../RadarLogo';
import HeaderMenu from './HeaderMenu';
import HeaderThemeSwitch from './HeaderThemeSwitch';
import Loader from '../loader/Loader';

const Header = () => {
  const headerLoading = false;
  return (
    <>
      <Loader loading={headerLoading} />
      <header className="header">
        <Container>
          <div className="header__elements">
            <RadarLogo />
            <div className="header__center">
              {/* <HeaderSearch /> */}
              {/* <HeaderNavigation /> */}
            </div>
            <div className="header__right">
              {/* <HeaderAuth /> */}
              <HeaderLanguage />
            </div>
            <HeaderMenu />
            <HeaderThemeSwitch />
          </div>
        </Container>
      </header>
    </>
  );
};

export default Header;

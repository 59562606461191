import React, { useEffect, useMemo } from 'react';
import { Col, Form, Modal, Row } from 'antd';
import Icon from '../../utils/Icon';
import { useEdit } from '../../../hooks/useEdit';
import { create, findOne, update } from '../../../servicies/users/roles';
import FormInput from '../../form/FormInput';
import { useTranslate } from '../../../hooks/useTranslate';
import useFormErrors from '../../../hooks/useFormErrors';
import TableFormItemWrapper from '../../utils/TableFormItemWrapper';
import useDatasource from '../../../hooks/useDatasource';
import { getAll } from '../../../servicies/users/permissions';
import Column from '../../utils/Columns';

const initial = {};

const EditRoleModal = ({ roleId, onClose = () => {}, onSave = () => {} }) => {
  const [form] = Form.useForm();
  const { t, translate } = useTranslate('admin.role');
  const { loading, content, handleChange } = useDatasource(getAll);

  const columns = useMemo(
    () => [
      Column.text('name', t('admin.permissions.name'), {
        width: 150,
        sort: false,
      }),
      Column.text('description', t('admin.permissions.description'), {
        width: 150,
        sort: false,
      }),
    ],
    [t],
  );

  const [entity, handleSubmit, errors] = useEdit({
    initial,
    existent: findOne,
    onCreate: create,
    onUpdate: update,
    isNew: roleId === 'new',
    id: roleId,
  });

  const handleSubmitTest = () => {
    const data = form.getFieldsValue();
    handleSubmit(data);
    onSave();
    onClose();
  };

  useFormErrors(form, errors);
  useEffect(() => {
    form.setFieldsValue(entity);
  }, [entity, form]);

  return (
    <Modal
      title={translate('_singular')}
      centered
      open={roleId}
      onOk={handleSubmitTest}
      onCancel={onClose}
      closeIcon={<Icon name="close" />}
      width={1200}
    >
      <Form form={form}>
        <Row>
          <Col span={12}>
            <FormInput name="name" translate={translate} />
            <FormInput name="description" translate={translate} />
          </Col>
          <Col span={12}>
            <Form.Item name="permissions" noStyle>
              <TableFormItemWrapper
                columns={columns}
                rowKey="name"
                loading={loading}
                pagination={false}
                dataSource={content.sort((a, b) =>
                  a.name.localeCompare(b.name),
                )}
                onTableChange={handleChange}
                scroll={{ y: 500 }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default EditRoleModal;

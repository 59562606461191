import React from 'react';
import FooterInfo from './FooterInfo';
import FooterCopyright from './FooterCopyright';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__content">
          <div className="footer__content-info">
            <FooterInfo />
            {/* <FooterLinks /> */}
          </div>
          <FooterCopyright />
        </div>
      </div>
    </footer>
  );
};

export default Footer;

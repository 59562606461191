import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import DownloadItemIcon from '../buttons/icons/DownloadItemIcon';
import { downloadFiles, getPartialPDF } from '../../servicies/files';
import Tags from '../utils/Tags';
import BackButton from '../buttons/BackButton';
import { findOne } from '../../servicies/mediafiles/magazine';
import MagazineHistory from './MagazineHistory';
import { useBatches } from './useBatches';
import MagazineMaximizeButton from './MagazineMaximizeButton';
import MagazinePages from './MagazinePages';
import MagazineBook from './MagazineBook';
import RadarLoader from '../loader/RadarLoader';

const VIEW_TYPE = {
  BOOK: { id: 1, type: 'book', title: 'Revista' },
  TWO: { id: 2, type: 'two', title: 'Lista cate 2' },
  THREE: { id: 3, type: 'three', title: 'Lista cate 3' },
};
// eslint-disable-next-line
const viewTypeList = [VIEW_TYPE.BOOK, VIEW_TYPE.TWO, VIEW_TYPE.THREE];

const MagazineMain = ({ magazineView, onCancel }) => {
  const [currentPage, setCurrentPage] = useState(null);
  const [magazine, setMagazine] = useState(null);
  const [maximize, setMaximize] = useState(false);
  const handle = useFullScreenHandle();
  const fullscreenRef = useRef(null);
  const flipBookRef = useRef(null);

  const handleCancel = useCallback(() => {
    setMagazine(null);
    if (onCancel && typeof onCancel === 'function') {
      onCancel();
    }
  }, [onCancel]);

  const handleMaximize = () => {
    setMaximize(true);
    handle.enter();
  };

  const handleMinimize = useCallback(() => {
    setMaximize(false);
    handle.exit();
  }, [handle]);

  useEffect(() => {
    findOne(magazineView?.id).then(setMagazine);
  }, [magazineView?.id]);

  const handleDownloadMagazine = useCallback(() => {
    downloadFiles(magazine?.pdfId).then().catch().finally();
  }, [magazine?.pdfId]);

  const getPages = useCallback((res) => {
    return res?.pages?.map((el) => {
      const dataUrl = `data:image/jpeg;base64,${el.base64Image}`;
      return {
        nbr: el.page,
        img: <img key={el.page} src={dataUrl} alt={`Page ${el.page}`} />,
      };
    });
  }, []);

  const flipToPage = useCallback((pageNumber) => {
    if (flipBookRef.current) {
      flipBookRef.current?.pageFlip()?.turnToPage(pageNumber);
      setCurrentPage(pageNumber);
    }
  }, []);

  const handleInputChange = (e) => {
    flipToPage(Number(e));
  };

  const { content, total, loading } = useBatches(getPartialPDF, 5, {
    id: magazineView?.pdfId,
    resultKey: 'pages',
    totalKey: 'totalPages',
    render: getPages,
    dependency: currentPage,
    triggerDifference: 10,
    setUniqueKey: 'nbr',
    contentKey: 'img',
  });

  const handleFlip = useCallback((e) => {
    const currentIndex = e.object?.pages?.currentPageIndex;
    setCurrentPage(currentIndex + 1);
  }, []);

  const onFullScreenChange = useCallback((e) => {
    setMaximize(e);
  }, []);

  return magazine?.id ? (
    <div className={`magazine__container ${maximize ? 'maximize' : ''}`}>
      <Tags list={magazine?.tags} />
      <FullScreen
        handle={handle}
        ref={fullscreenRef}
        onChange={(e) => onFullScreenChange(e)}
      >
        <div
          className={`magazine__overlay-content  ${maximize ? 'active' : ''}`}
        >
          <div className="magazine__functional">
            {maximize ? null : <BackButton onCancel={handleCancel} />}

            <div className="magazine__functional-btns">
              <DownloadItemIcon onClick={handleDownloadMagazine} />
              {/* // TODO: De adaugat pe viitor */}
              {/* <ViewSelect dataSource={viewTypeList} /> */}

              {magazine?.history?.length ? (
                <MagazineHistory
                  dataSet={magazine?.history}
                  onPageClick={flipToPage}
                  maximize={maximize}
                />
              ) : null}
              <MagazineMaximizeButton
                maximize={maximize}
                handleMaximize={handleMaximize}
                handleMinimize={handleMinimize}
              />
            </div>
          </div>

          <div className="magazine__book">
            <MagazineBook
              ref={flipBookRef}
              maximize={maximize}
              content={content}
              handleFlip={handleFlip}
            />
            <div className="magazine__content-bottom">
              <MagazinePages
                currentPage={currentPage}
                handleInputChange={handleInputChange}
                total={total}
              />
              <RadarLoader size={60} loading={loading} />
            </div>
          </div>
        </div>
      </FullScreen>
    </div>
  ) : null;
};

export default MagazineMain;

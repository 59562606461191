import React, { useMemo } from 'react';
import { Form, Input } from 'antd';

const FormInput = ({
  name,
  label,
  translate,
  disabled,
  labelCol = 24,
  rules,
  placeholder,
  required,
  formProps = {},
  inputProps = {},
}) => {
  const labelText = useMemo(() => {
    const isTranslatable = translate && typeof translate === 'function';
    return isTranslatable ? translate(name) : label;
  }, [label, translate, name]);

  return (
    <Form.Item
      name={name}
      label={labelText}
      labelCol={{ span: labelCol }}
      rules={rules}
      required={required}
      {...formProps}
    >
      <Input
        name={name}
        disabled={disabled}
        placeholder={placeholder}
        {...inputProps}
      />
    </Form.Item>
  );
};

export default FormInput;

import React from 'react';
import CountriesList from '../../../components/taxonomies/countries/CountriesList';
import { useTranslate } from '../../../hooks/useTranslate';
import Country from './Country';

const Countries = () => {
  const { t, translate } = useTranslate('admin.taxonomies.countries');
  return (
    <div>
      <Country translate={translate} />
      <CountriesList t={t} translate={translate} />
    </div>
  );
};

export default Countries;

import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Icon from './Icon';

const NavigableBlock = ({
  icon = '',
  name,
  path = '/',
  onClick = undefined,
}) => {
  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    if (onClick && typeof onClick === 'function') {
      onClick();
    } else {
      navigate(path);
    }
  }, [onClick, navigate, path]);

  return (
    <div
      style={{
        margin: '5px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <button
        type="button"
        onClick={handleClick}
        style={{
          width: '200px',
          height: '200px',
          borderRadius: '10px',
          border: '1px solid black',
          cursor: 'pointer',
          backgroundColor: 'transparent', // Transparent background to mimic div
        }}
      >
        <Icon name={icon} />
      </button>
      {name}
    </div>
  );
};

export default NavigableBlock;

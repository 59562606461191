import React, { useMemo } from 'react';
import { Table } from 'antd';
import useDatasource from '../../../hooks/useDatasource';
import { findAll, remove } from '../../../servicies/taxonomies/countries';
import Column from '../../utils/Columns';
import { AuthData, hasPermission } from '../../auth/AuthWrapper';
import ViewItemIcon from '../../buttons/icons/ViewItemIcon';
import EditItemIcon from '../../buttons/icons/EditItemIcon';
import DeleteItemIcon from '../../buttons/icons/DeleteItemIcon';

const CountriesList = ({ t, translate }) => {
  const { userContext } = AuthData();
  const { user } = userContext;
  const { permissions } = user;

  const { loading, pagination, content, handleChange, reload } =
    useDatasource(findAll);

  const columns = useMemo(
    () => [
      Column.text('alpha2', translate, {
        width: 100,
        filter: true,
      }),
      Column.text('alpha3', translate, {
        width: 100,
        filter: true,
      }),
      Column.text('name', translate, {
        width: 300,
        filter: true,
      }),
      Column.date('createdAt', translate, {
        width: 250,
        format: 'DD-MM-YYYY HH:mm:ss',
      }),
      Column.text('createdBy', translate, {
        width: 150,
      }),
      Column.date('updatedAt', translate, {
        width: 250,
        format: 'DD-MM-YYYY HH:mm:ss',
      }),
      Column.text('updatedBy', translate, {
        width: 150,
      }),
      Column.bool('deleted', translate, {
        width: 150,
        filter: true,
        inverted: true,
        labels: [
          t('table.filter.all'),
          t('entity._.deleted.true'),
          t('entity._.deleted.false'),
          t('entity._.deleted.unknown'),
        ],
      }),
      Column.actions(t('table.actions'), (record) =>
        record.deleted ? (
          <span>
            {hasPermission(permissions, 'PUBLIC_TAXONOMY_SEARCH') && (
              <ViewItemIcon path={`/admin/taxonomies/countries/${record.id}`} />
            )}
          </span>
        ) : (
          <span style={{ textAlign: 'right' }}>
            {hasPermission(permissions, 'ADMIN_TAXONOMY_MANAGEMENT') && (
              <EditItemIcon path={`/admin/taxonomies/countries/${record.id}`} />
            )}
            {hasPermission(permissions, 'ADMIN_TAXONOMY_MANAGEMENT') && (
              <DeleteItemIcon
                title={t('entity.admin.country._delete', record)}
                message={t('entity.admin.country._deleted', record)}
                item={record}
                action={remove}
                reload={reload}
              />
            )}
          </span>
        ),
      ),
    ],
    [translate, t, permissions, reload],
  );

  return (
    <div>
      <Table
        scroll={{ x: 500 }}
        columns={columns}
        rowKey="id"
        loading={loading}
        pagination={pagination}
        dataSource={content}
        onChange={handleChange}
      />
    </div>
  );
};

export default CountriesList;

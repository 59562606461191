import React from 'react';
import { EyeOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useTranslate } from '../../../hooks/useTranslate';

const ViewItemIcon = ({ title, path, disabled = false, onClick = null }) => {
  const navigate = useNavigate();
  const { translate } = useTranslate('actions');

  return (
    <Tooltip title={translate('view')}>
      <Button
        shape="circle"
        type="link"
        icon={<EyeOutlined />}
        onClick={() =>
          onClick && typeof onClick === 'function' ? onClick() : navigate(path)
        }
        disabled={disabled}
      >
        {title || ''}
      </Button>
    </Tooltip>
  );
};

export default ViewItemIcon;

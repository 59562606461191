import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18n';

import './styles/styles.scss';

if (process.env.NODE_ENV !== 'production') {
  window._env_ = window._env_ || {};
  window._env_.API_BACKEND_URL = process.env.REACT_APP_API_BACKEND_URL;
}

const root = ReactDOM.createRoot(document.getElementById('root'));
// eslint-disable-next-line jest/require-hook
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
);

// eslint-disable-next-line jest/require-hook
reportWebVitals();

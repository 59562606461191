import React, { useCallback } from 'react';

const Tags = ({ list }) => {
  const handleTag = useCallback(() => {}, []);
  return (
    <div className="tags">
      {list
        ?.filter((el) => el)
        ?.map((tag) => {
          return (
            <button onClick={handleTag} type="button" key={tag} className="tag">
              #{tag.startsWith('#') ? tag.substring(2) : tag}
            </button>
          );
        })}
    </div>
  );
};

export default Tags;

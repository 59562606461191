import React, { useMemo } from 'react';
import { Table } from 'antd';
import useDatasource from '../../../hooks/useDatasource';
import { findAll, remove } from '../../../servicies/users/users';
import Column from '../../utils/Columns';
import { useTranslate } from '../../../hooks/useTranslate';
import { AuthData, hasPermission } from '../../auth/AuthWrapper';
import DeleteItemIcon from '../../buttons/icons/DeleteItemIcon';
import ViewItemIcon from '../../buttons/icons/ViewItemIcon';
import EditItemIcon from '../../buttons/icons/EditItemIcon';
import Container from '../../../utils/layout/Container';

const UsersList = () => {
  const { userContext } = AuthData();
  const { user } = userContext;
  const { permissions } = user;

  const { translate, t } = useTranslate('admin.users');
  const { loading, pagination, content, handleChange, reload } =
    useDatasource(findAll);

  const columns = useMemo(
    () => [
      Column.text('id', 'ID', {
        width: 50,
        filter: true,
      }),
      Column.text('username', translate, {
        width: 100,
        filter: true,
      }),
      Column.text('email', translate, {
        width: 100,
        filter: true,
      }),
      Column.text('role', translate, {
        width: 150,
        filter: true,
      }),
      Column.date('createdAt', t('entity.createdAt'), {
        width: 100,
        format: 'DD-MM-YYYY HH:mm:ss',
        filter: true,
      }),
      Column.bool('deleted', t('entity.deleted._'), {
        width: 100,
        filter: true,
        inverted: true,
        labels: [
          t('entity.deleted.all'),
          t('entity.deleted.true'),
          t('entity.deleted.false'),
          t('entity.deleted.unknown'),
        ],
      }),
      Column.actions(t, (record) =>
        record.deleted ? (
          <span style={{ textAlign: 'right' }}>
            {hasPermission(permissions, 'USERS_MANAGEMENT') && (
              <ViewItemIcon path={`/admin/users/users/${record.id}`} />
            )}
          </span>
        ) : (
          <span style={{ textAlign: 'right' }}>
            {hasPermission(permissions, 'USERS_MANAGEMENT') && (
              <EditItemIcon path={`/admin/users/users/${record.id}`} />
            )}
            {hasPermission(permissions, 'USERS_MANAGEMENT') && (
              <DeleteItemIcon
                title={t('entity.admin.user._delete', record)}
                message={t('entity.admin.user._deleted', record)}
                item={record}
                action={remove}
                reload={reload}
              />
            )}
          </span>
        ),
      ),
    ],
    [t, reload, translate, permissions],
  );

  return (
    <div className="users">
      <Container>
        <div className="users__content">
          <Table
            loading={loading}
            pagination={pagination}
            dataSource={content}
            onChange={handleChange}
            columns={columns}
          />
        </div>
      </Container>
    </div>
  );
};

export default UsersList;

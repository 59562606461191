import { get, remove, handleHttpErrors, PUBLIC_ENV } from './api';

const BASE_PATH = '/files/api/v1';
const BASE_PDF = '/files/api/v2/pdf';

// window._env_.API_BACKEND_URL
export const downloadFile = async (endpoint, options = {}) =>
  fetch(`${PUBLIC_ENV}${endpoint}`, {
    method: 'GET',
    credentials: 'include',
    headers: {
      ...(options.headers || {}),
    },
    ...options,
  }).then(handleHttpErrors);

export const postFiles = async (endpoint, data, options = {}) =>
  fetch(`${PUBLIC_ENV}${endpoint}`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      ...(options.headers || {}),
    },
    ...(data ? { body: data } : {}),
    ...options,
  }).then(handleHttpErrors);

export const uploadFiles = async (files) => {
  if (files && files.length === 0) {
    return [];
  }
  const data = new FormData();
  files.forEach((file) => data.append('files', file, file.name));
  return postFiles(`${BASE_PATH}/`, data).then((res) => res.json());
};

export const uploadFilesFromQR = async (uuid, files) => {
  if (files && files.length === 0) {
    return [];
  }
  const data = new FormData();
  files.forEach((file) => data.append('files', file, file.name));
  return postFiles(`${BASE_PATH}/qr/${uuid}`, data).then((res) => res.json());
};

export const getFiles = async (id) =>
  get(`${BASE_PATH}/${Array.isArray(id) ? id.join(',') : id}`);

export const downloadFiles = async (id) =>
  downloadFile(`${BASE_PATH}/${Array.isArray(id) ? id.join(',') : id}?download`)
    .then((res) => res.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.target = '_blank';
      document.body.appendChild(a);
      a.click();
      a.remove();
    });

export const getBlobFile = async (id) => {
  const downloadUrl = `${BASE_PATH}/${
    Array.isArray(id) ? id.join(',') : id
  }?download`;

  try {
    const blob = await downloadFile(downloadUrl).then((res) => res.blob());
    return blob;
  } catch (error) {
    console.error('Error occurred while fetching the file:', error);
    throw error;
  }
};

export const getPartialPDF = (magazineId, startPage, endPage) =>
  get(`${BASE_PDF}/convert-page/${magazineId}/${startPage}/${endPage}`);

export const removeFile = async (id) =>
  remove(`${BASE_PATH}/${Array.isArray(id) ? id.join(',') : id}`);

import React from 'react';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import Icon from '../utils/Icon';

const translate = {
  _: 'components.buttons.saveButton',
};
const SaveButton = ({ disabled = false, onSave, buttonText, ...rest }) => {
  const { t } = useTranslation();

  return (
    <Button
      type="primary"
      htmlType={onSave ? undefined : 'submit'}
      onClick={onSave || undefined}
      icon={<Icon name="save" />}
      disabled={disabled}
      {...rest}
    >
      {buttonText || t(translate._)}
    </Button>
  );
};

export default SaveButton;

import React from 'react';
import { Button, Tooltip } from 'antd';
import Icon from '../utils/Icon';
import { useTranslate } from '../../hooks/useTranslate';

const MagazineMaximizeButton = ({
  maximize,
  handleMinimize = () => {},
  handleMaximize = () => {},
}) => {
  const { translate } = useTranslate('pages.magazine');

  return (
    <Tooltip title={maximize ? translate('minimize') : translate('maximize')}>
      <Button
        onClick={maximize ? handleMinimize : handleMaximize}
        type="link"
        icon={maximize ? <Icon name="minimize" /> : <Icon name="maximize" />}
      />
    </Tooltip>
  );
};

export default MagazineMaximizeButton;

import React from 'react';
import Container from '../../../utils/layout/Container';

const Stats = () => {
  return (
    <div className="stats">
      <Container>
        <div className="stats__content">
          This page will represent app statistic ... users logining .. other
          util information to manage app profit and user interests
        </div>
      </Container>
    </div>
  );
};

export default Stats;

import React from 'react';
import Icon from '../utils/Icon';

const SocialButtons = ({ facebook, youtube, linkedin, instagram }) => {
  return (
    <div className="button__social">
      {facebook ? (
        <a
          href={facebook}
          target="_blank"
          className="button__social-link"
          rel="noreferrer"
        >
          <Icon name="facebook" />
        </a>
      ) : null}
      {instagram ? (
        <a
          href={instagram}
          target="_blank"
          className="button__social-link"
          rel="noreferrer"
        >
          <Icon name="instagram" />
        </a>
      ) : null}
      {linkedin ? (
        <a
          href={linkedin}
          target="_blank"
          className="button__social-link"
          rel="noreferrer"
        >
          <Icon name="linkedin" />
        </a>
      ) : null}
      {youtube ? (
        <a
          href={youtube}
          target="_blank"
          className="button__social-link"
          rel="noreferrer"
        >
          <Icon name="youtube" />
        </a>
      ) : null}
    </div>
  );
};

export default SocialButtons;

import React, { useEffect, useMemo } from 'react';
import { Form, Modal } from 'antd';
import useFormErrors from '../../hooks/useFormErrors';
import FormInput from '../form/FormInput';
import FormDatePicker from '../form/FormDatePicker';
import Icon from '../utils/Icon';
import { DATE, INPUT } from '../../utils/defaults/fields';
import SaveButton from '../buttons/SaveButton';

const ModalTaxBuilder = ({
  open = false,
  title,
  fields = [{ name: 'NoName', type: INPUT }],
  translate,
  entity = undefined,
  onSave = () => {},
  onCancel = () => {},
  errors,
}) => {
  const [form] = Form.useForm();

  useFormErrors(form, errors);

  const renderFields = useMemo(() => {
    return fields?.map((field, index) => {
      const key = new Date().getTime().toString().concat(String(index));
      const fieldType = field.type;
      const fieldName = field.name;

      if (fieldType === INPUT) {
        return (
          <FormInput
            key={key}
            name={fieldName}
            translate={translate}
            inputProps={field}
          />
        );
      }
      if (fieldType === DATE) {
        return (
          <FormDatePicker
            key={key}
            name={fieldName}
            translate={translate}
            inputProps={field}
          />
        );
      }

      throw new Error('unknown field type');
    });
  }, [translate, fields]);

  useEffect(() => {
    form.setFieldsValue(entity);
  }, [form, entity]);

  return (
    <div>
      <Modal
        open={open}
        onCancel={onCancel}
        closeIcon={<Icon name="close" />}
        width={800}
        footer={null}
        title={
          translate && typeof translate === 'function'
            ? translate(title)
            : title
        }
      >
        <Form form={form} onFinish={onSave}>
          {renderFields}
          <SaveButton />
        </Form>
      </Modal>
    </div>
  );
};

export default ModalTaxBuilder;

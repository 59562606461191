import React, { useMemo } from 'react';
import { Table } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import Container from '../../../utils/layout/Container';
import { useTranslate } from '../../../hooks/useTranslate';
import useDatasource from '../../../hooks/useDatasource';
import { findAll } from '../../../servicies/users/permissions';
import Column from '../../utils/Columns';

const PermissionsList = () => {
  const { translate } = useTranslate('admin.permissions');
  const { loading, pagination, content, handleChange } = useDatasource(findAll);

  const columns = useMemo(
    () => [
      Column.text('id', translate, {
        width: 50,
      }),
      Column.text('name', translate, {
        width: 275,
        filter: true,
      }),
      Column.text('description', translate, {
        width: 275,
        filter: true,
      }),
    ],
    [translate],
  );

  return (
    <div className="permissions">
      <Container>
        <div className="permissions__content">
          <PageHeader title={translate('_plural')} />
          <Table
            columns={columns}
            rowKey="id"
            loading={loading}
            pagination={pagination}
            dataSource={content}
            onChange={handleChange}
            sticky={{ offsetHeader: 63 }}
          />
        </div>
      </Container>
    </div>
  );
};

export default PermissionsList;

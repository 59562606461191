import React from 'react';
import { Helmet } from 'react-helmet';
import img from '../../assets/images/logoSmall.png';

const HelmetComponent = ({ content }) => {
  const { title, description, keywords, author, url } = content;
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta name="author" content={author} />
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta property="og:image" content={img} />

      <link rel="canonical" href={url} />
    </Helmet>
  );
};

export default HelmetComponent;

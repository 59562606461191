import React from 'react';
import { Layout, Menu } from 'antd';
import { useTranslate } from '../../hooks/useTranslate';
import { ADMIN_PAGE_KEYS } from '../../pages/admin/keys';
import Icon from '../utils/Icon';

const Sidebar = ({ selected = '', onSelect = () => {} }) => {
  const { Sider } = Layout;
  const { translate } = useTranslate('admin.sidebar');

  const handleSelect = ({ key }) => key && onSelect(key);

  return (
    <Sider
      collapsible={false}
      mode="horizontal"
      style={{
        overflow: 'auto',
        height: '100vh',
        position: 'fixed',
        left: 0,
        display: 'block',
      }}
    >
      <Menu
        mode="inline"
        style={{
          height: '100%',
          borderRight: 0,
        }}
        onClick={handleSelect}
        selectedKeys={selected}
      >
        <Menu.SubMenu
          key="/app"
          icon={<Icon name="application" />}
          title={translate('app')}
        >
          <Menu.Item
            key={ADMIN_PAGE_KEYS.TAXONOMIES.path}
            icon={<Icon name="taxonomies" />}
          >
            {translate(ADMIN_PAGE_KEYS.TAXONOMIES.name)}
          </Menu.Item>
        </Menu.SubMenu>
        <Menu.SubMenu
          key="/users"
          icon={<Icon name="blog" />}
          title={translate('users')}
        >
          <Menu.Item
            key={ADMIN_PAGE_KEYS.USERS.path}
            icon={<Icon name="blog" />}
          >
            {translate(ADMIN_PAGE_KEYS.USERS.name)}
          </Menu.Item>
          <Menu.Item
            key={ADMIN_PAGE_KEYS.ROLES.path}
            icon={<Icon name="document" />}
          >
            {translate(ADMIN_PAGE_KEYS.ROLES.name)}
          </Menu.Item>
          <Menu.Item
            key={ADMIN_PAGE_KEYS.PERMISSIONS.path}
            icon={<Icon name="permissions" />}
          >
            {translate(ADMIN_PAGE_KEYS.PERMISSIONS.name)}
          </Menu.Item>
        </Menu.SubMenu>
        <Menu.Item
          key={ADMIN_PAGE_KEYS.MAGAZINE.path}
          icon={<Icon name="book" />}
        >
          {translate(ADMIN_PAGE_KEYS.MAGAZINE.name)}
        </Menu.Item>
        <Menu.Item
          key={ADMIN_PAGE_KEYS.STATS.path}
          icon={<Icon name="stats" />}
        >
          {translate(ADMIN_PAGE_KEYS.STATS.name)}
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

export default Sidebar;

import React, { useState } from 'react';
import { Empty, Layout } from 'antd';
import Sidebar from '../../components/admin/Sidebar';
import { ADMIN_PAGE_KEYS } from './keys';
import TaxonomiesList from '../../components/admin/taxonomies/TaxonomiesList';
import UsersList from '../../components/admin/users/UsersList';
import PermissionsList from '../../components/admin/users/PermissionsList';
import RolesList from '../../components/admin/users/RolesList';
import Stats from '../../components/admin/stats/Stats';
import MagazineList from '../../components/admin/mediafiles/MagazineList';

const Settings = () => {
  const [selected, setSelected] = useState(null);

  const handleSelect = (value) => {
    if (value === selected) {
      setSelected(null);
    } else {
      setSelected(value);
    }
  };

  const renderSelected = () => {
    switch (selected) {
      case ADMIN_PAGE_KEYS.TAXONOMIES.path:
        return <TaxonomiesList />;
      case ADMIN_PAGE_KEYS.ROLES.path:
        return <RolesList />;
      case ADMIN_PAGE_KEYS.PERMISSIONS.path:
        return <PermissionsList />;
      case ADMIN_PAGE_KEYS.USERS.path:
        return <UsersList />;
      case ADMIN_PAGE_KEYS.STATS.path:
        return <Stats />;
      case ADMIN_PAGE_KEYS.MAGAZINE.path:
        return <MagazineList />;
      default:
        return <Empty />;
    }
  };

  return (
    <Layout>
      <Layout.Sider width="auto">
        <Sidebar selected={selected} onSelect={handleSelect} />
      </Layout.Sider>
      <Layout.Content>
        <div className="settings__tab"> {renderSelected()}</div>
      </Layout.Content>
    </Layout>
  );
};

export default Settings;

import React, { useEffect } from 'react';
import { useForm } from 'antd/es/form/Form';
import { Form } from 'antd';
import { debounce } from 'lodash';
import FormInput from '../form/FormInput';

const MagazinePages = ({
  currentPage,
  handleInputChange = () => {},
  total,
}) => {
  const [form] = useForm();
  const handleChange = debounce((e) => {
    handleInputChange(e.target.value);
  }, 500);
  useEffect(() => {
    form.setFieldsValue({ currentPage });
  }, [currentPage, form]);

  return (
    <div className="magazine__counter">
      <div className="magazine__counter-elem">
        <Form
          initialValues={{ currentPage }}
          form={form}
          onChange={handleChange}
        >
          <FormInput name="currentPage" />
        </Form>
        <span>/</span>
        <div>{total}</div>
      </div>
    </div>
  );
};

export default MagazinePages;

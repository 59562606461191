import React from 'react';
import { PUBLIC_PERMS } from '../auth/AuthWrapper';
import { Forbidden, NotFound } from '../../pages';
import Settings from '../../pages/admin/Settings';
import Magazine from '../../pages/magazine/Magazine';

export const NAV = [
  {
    path: '/forbidden',
    name: 'Page Not found',
    element: <Forbidden />,
  },
  {
    path: '*',
    name: 'Page Not found',
    element: <NotFound />,
  },
  // {
  //   path: '/',
  //   name: 'Home',
  //   element: <Home />,
  // },
  {
    path: '/',
    name: 'Magazine',
    element: <Magazine />,
  },
  // {
  //   path: '/personal/user/:id',
  //   name: 'User',
  //   element: <User />,
  // },
  // {
  //   path: '/personal/curriculum-vitae',
  //   name: 'CvList',
  //   element: <CurriculumVitae />,
  //   authorities: [PUBLIC_PERMS.AUTHENTICATED],
  // },
  // {
  //   path: '/personal/curriculum-vitae/:id',
  //   name: 'CV',
  //   element: <EditCurriculumVitae />,
  //   authorities: [PUBLIC_PERMS.AUTHENTICATED],
  // },
  {
    path: '/admin/settings',
    name: 'settings',
    element: <Settings />,
    authorities: [PUBLIC_PERMS.AUTHENTICATED], // TODO: modify to perms admin
  },
  // {
  //   path: '/admin/taxonomies/countries',
  //   name: 'tax_countries',
  //   element: <Countries />,
  //   authorities: ['ADMIN_TAXONOMY_MANAGEMENT'],
  // },
  // {
  //   path: '/admin/taxonomies/countries/:id',
  //   name: 'tax_countries',
  //   element: <Country />,
  //   authorities: ['ADMIN_TAXONOMY_MANAGEMENT'],
  // },
];

import React, { useEffect, useState } from 'react';
import HTMLFlipBook from 'react-pageflip';
import loadingImg from '../../assets/gif/loading.gif';

const MagazineBook = React.forwardRef(
  ({ maximize, content, handleFlip = () => {} }, ref) => {
    const [key, setKey] = useState(0);
    const [dimensions, setDimensions] = useState({
      width: maximize ? 750 : 500,
      height: maximize ? 980 : 750,
    });
    useEffect(() => {
      setKey((prevKey) => prevKey + 1);
    }, [maximize]);

    useEffect(() => {
      // Function to update dimensions
      const updateDimensions = () => {
        setDimensions({
          width: maximize ? 750 : 500,
          height: maximize ? 980 : 750,
        });
      };

      // Add event listener to window resize
      window.addEventListener('resize', updateDimensions);

      // Call the function initially to set the correct dimensions
      updateDimensions();

      // Clean up event listener on component unmount
      return () => window.removeEventListener('resize', updateDimensions);
    }, [maximize]);

    return (
      <div className="magazine__book-view">
        <HTMLFlipBook
          key={key}
          ref={ref}
          width={dimensions.width}
          maxWidth={dimensions.width}
          height={dimensions.height}
          maxHeight={dimensions.height}
          // showCover
          className={`magazine__book-flip ${maximize ? 'maximize' : ''}`}
          onInit={handleFlip}
          onFlip={handleFlip}
          size="stretch"
          mobileScrollSupport
        >
          {content?.map(
            (el) =>
              el?.img || <img key={el?.nbr} src={loadingImg} alt={el?.nbr} />,
          )}
        </HTMLFlipBook>
      </div>
    );
  },
);
export default MagazineBook;

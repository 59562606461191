import { useCallback, useEffect, useState } from 'react';

export function useBatches(
  action,
  batchSize = 5,
  options = {
    id: undefined,
    start: 1,
    idKey: 'id',
    totalKey: 'total',
    resultKey: undefined,
    render: undefined,
    setUniqueKey: undefined,
    contentKey: undefined,
    dependency: undefined,
    triggerDifference: 5,
  },
) {
  const [range, setRange] = useState({
    from: options?.start || 1,
    to: (options?.start || 1) + batchSize,
  });

  const [total, setTotal] = useState(batchSize);
  const [content, setContent] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!loading && content?.length < total && total !== batchSize) {
      const generatedContent = [];
      for (let i = content.length + 1; i <= total; i += 1) {
        const obj = {};
        obj[options?.setUniqueKey] = i;
        generatedContent.push(obj);
      }
      setContent([...content, ...generatedContent]);
    }
  }, [batchSize, content, loading, options?.setUniqueKey, total]);

  const fetcher = useCallback(
    (params) => {
      const { id, totalKey, render, resultKey } = options;

      if (loading || id === undefined) {
        return;
      }

      setLoading(true);
      action(id, params?.from || range.from, params?.to || range.to)
        .then((res) => {
          setTotal(res[totalKey]);
          setContent((prev) => {
            let newContent;
            if (render && typeof render === 'function') {
              newContent = render(res);
            } else if (resultKey) {
              newContent = res[resultKey];
            } else {
              newContent = res;
            }

            if (options?.setUniqueKey) {
              const combinedContent = [...prev, ...newContent];

              const uniqueContentMap = new Map();
              combinedContent.forEach((item) => {
                uniqueContentMap.set(item[options?.setUniqueKey], item);
              });

              return Array.from(uniqueContentMap.values()).sort(
                (a, b) => a[options?.setUniqueKey] - b[options?.setUniqueKey],
              );
            }
            return [...prev, ...newContent];
          });
        })
        .finally(() => setLoading(false));
    },
    [action, range.from, range.to, loading, options],
  );

  useEffect(() => {
    if (loading) {
      return;
    }

    const dependency = options?.dependency ?? 1;
    const notRenderedContent = content
      ?.filter((el) => !el[options?.contentKey])
      ?.map((el) => el[options.setUniqueKey]);

    if (notRenderedContent.length > 0) {
      const closest = notRenderedContent.reduce((c, num) => {
        return Math.abs(num - dependency) < Math.abs(c - dependency) ? num : c;
      }, notRenderedContent[0]);

      const maxParam = Math.max(dependency, closest);
      const minParam = Math.min(dependency, closest);
      if (options?.triggerDifference < maxParam - minParam) {
        return;
      }

      if (closest < dependency || closest === total) {
        setRange({
          from: Math.max(closest - batchSize, 1),
          to: Math.min(closest, total),
        });
      } else {
        setRange({ from: closest, to: Math.min(closest + batchSize, total) });
      }
    }
  }, [
    batchSize,
    content,
    loading,
    options?.contentKey,
    options?.dependency,
    options.setUniqueKey,
    options?.triggerDifference,
    total,
  ]);

  useEffect(() => {
    if (range.to !== range.from) {
      fetcher();
    }
    // eslint-disable-next-line
  }, [range]);

  return { content, total, loading };
}

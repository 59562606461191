import React, { useState } from 'react';
import AddMagazineModal from './AddMagazineModal';
import Container from '../../../utils/layout/Container';
import MagazineTable from './MagazineTable';
import useDatasource from '../../../hooks/useDatasource';
import { findAll } from '../../../servicies/mediafiles/magazine';

const MagazineList = () => {
  const [selectedMagazine, setSelectedMagazine] = useState(null);
  const { reload } = useDatasource(findAll);
  return (
    <div className="admin__magazine">
      <Container>
        <div className="admin__magazine-list">
          <AddMagazineModal
            magazineId={selectedMagazine}
            onSave={reload}
            onClose={() => setSelectedMagazine(null)}
          />
          <MagazineTable handleSelect={setSelectedMagazine} />
        </div>
      </Container>
    </div>
  );
};

export default MagazineList;

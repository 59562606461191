import React, { useCallback, useEffect, useState } from 'react';
import MagazineEditHistoryModal from './MagazineEditHistoryModal';
import MagazineEditHistoryItem from './MagazineEditHistoryItem';

const MagazineEditHistoryList = ({ initial = [], onChange }) => {
  const [history, setHistory] = useState(initial);

  const handleAdd = useCallback((entity) => {
    setHistory((prev) => {
      prev.sort((a, b) => a.toPage - b.toPage);
      const last = prev[prev.length - 1];
      console.log(last, entity, entity?.fromPage <= last?.toPage);
      if (last?.toPage && Number(entity.fromPage) <= Number(last.toPage)) {
        return prev;
      }
      console.log([...prev, entity], 'log');
      return [...prev, entity];
    });
  }, []);

  const handleDelete = useCallback((id) => {
    // TODO: check that
    setHistory((prev) => prev.filter((el) => (el.tempId || el.id) !== id));
  }, []);

  useEffect(() => {
    if (onChange && typeof onChange === 'function') {
      onChange(history);
    }
    console.log(history, 'history');
  }, [history, onChange]);

  return (
    <div className="magazine__modal-history">
      <MagazineEditHistoryModal onAdd={handleAdd} />
      <MagazineEditHistoryItem
        history={history}
        handleDelete={handleDelete}
        modal
      />
    </div>
  );
};

export default MagazineEditHistoryList;

import React, { useCallback } from 'react';
import { Button, notification, Popconfirm, Tooltip } from 'antd';
import { useTranslate } from '../../../hooks/useTranslate';
import Icon from '../../utils/Icon';

const DeleteItemIcon = ({
  title,
  message,
  item,
  onClick,
  reload = () => {},
  disabled = false,
  isPromise = true,
}) => {
  const { translate } = useTranslate('components.buttons');

  const handleDelete = useCallback(() => {
    if (!isPromise) {
      if (onClick && typeof onClick === 'function') {
        onClick();
      }
      return;
    }
    onClick(item)
      .then(() => message && notification.success({ message, duration: 3 }))
      .finally(() => {
        reload();
      });
  }, [isPromise, onClick, item, message, reload]);

  return (
    <Popconfirm
      placement="topLeft"
      title={title}
      disabled={disabled}
      onConfirm={handleDelete}
      okText={translate('deleteButton')}
      cancelText={translate('cancelButton')}
    >
      <Tooltip title={translate('deleteButton')}>
        <Button
          shape="circle"
          type="link"
          icon={<Icon name="delete" />}
          disabled={disabled}
        />
      </Tooltip>
    </Popconfirm>
  );
};

export default DeleteItemIcon;

import React from 'react';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="not-found">
      <div className="nf">
        <h1>Error 404</h1>
        <h2>{t('errors.client.pageNotFound')}</h2>
        <br />
        <Button onClick={goBack}>{t('components.buttons.goHomeBtn')}</Button>
      </div>
    </div>
  );
};

export default NotFound;

import React, { useEffect, useState } from 'react';
import { Switch } from 'antd';
import Icon from '../utils/Icon';

const HeaderThemeSwitch = () => {
  const [theme, setTheme] = useState(
    localStorage.getItem('them') ? localStorage.getItem('them') : true,
  );
  const body = document.querySelector('body');
  const localTheme = localStorage.getItem('them');
  const handleChangeTheme = (check) => {
    if (check) {
      localStorage.setItem('them', true);
      body.classList.add('light');
      body.classList.remove('dark');
      setTheme(true);
    } else if (!check) {
      localStorage.setItem('them', false);
      body.classList.add('dark');
      body.classList.remove('light');
      setTheme(false);
    }
  };

  useEffect(() => {
    if (localTheme && localTheme === 'true') {
      body.classList.add('light');
      body.classList.remove('dark');
      setTheme(true);
    } else if (localTheme && localTheme === 'false') {
      body.classList.add('dark');
      body.classList.remove('light');
      setTheme(false);
    } else {
      localStorage.setItem('them', true);
      body.classList.add('light');
      body.classList.remove('dark');
      setTheme(true);
    }
    document.body.classList.add('notranslate');

    // eslint-disable-next-line
  }, [body.classList, localTheme]);

  return (
    <div className="theme__switcher">
      <Switch
        checked={theme}
        onChange={handleChangeTheme}
        checkedChildren={<Icon name="sun" />}
        unCheckedChildren={<Icon name="moon" />}
      />
    </div>
  );
};

export default HeaderThemeSwitch;

import React, { useState } from 'react';
import Container from '../../utils/layout/Container';
import MagazineMain from '../../components/magazine/MagazineMain';
import MagazinesListView from '../../components/magazine/MagazinesListView';
import HelmetComponent from '../../components/utils/HelmetComponent';
import { MAGAZINE } from '../../components/utils/helmet';

const Magazine = () => {
  const [selected, setSelected] = useState(null);

  return (
    <div className="magazine">
      <HelmetComponent content={MAGAZINE} />
      <Container>
        <div className="magazine__content-elem">
          <div className="magazine__content">
            {selected ? (
              <MagazineMain
                magazineView={selected}
                onCancel={() => setSelected(null)}
              />
            ) : (
              <div className="magazine__list-view">
                <MagazinesListView onSelect={setSelected} />
                {/* <Pagination /> */}
              </div>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Magazine;

import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import Icon from '../utils/Icon';

const LANG = {
  EN: { id: 'en', name: 'En' },
  RO: { id: 'ro', name: 'Ro' },
  RU: { id: 'ru', name: 'Ру' },
};
const HeaderLanguage = () => {
  const { i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem('lang') || LANG.EN.id,
  );
  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('lang'));
  }, [i18n]);

  const languagesArray = [LANG.EN, LANG.RO, LANG.RU];
  const setLang = (lang) => {
    i18n.changeLanguage(lang);
    localStorage.setItem('lang', lang);
    setSelectedLanguage(lang);
  };

  return (
    <Select
      className="header__language-select"
      autoComplete="none"
      defaultValue={selectedLanguage}
      suffixIcon={<Icon name="arrow" />}
      onChange={(e) => setLang(e)}
    >
      {languagesArray?.map((ln) => (
        <Select.Option value={ln.id} key={ln.id}>
          {ln.name}
        </Select.Option>
      ))}
    </Select>
  );
};
export default HeaderLanguage;

import React, { useCallback, useState } from 'react';
import { Button, notification } from 'antd';
import { useDropzone } from 'react-dropzone';
import { useTranslate } from '../../hooks/useTranslate';

const FileUploader = ({
  disabled = false,
  onUploadComplete = () => {},
  children,
  accept,
  multiple = true,
  icon = null,
  title = 'Upload File',
  toBase64 = false,
  block = false,
}) => {
  const { t } = useTranslate();
  const limitFileSize = parseInt(window._env_.FILE_SIZE_LIMIT_BYTES, 10);
  const [loading, setLoading] = useState(false);
  const { translate } = useTranslate('components.files');

  // TODO: make a loader for files near add button
  // Denis sota nada pridumati
  console.log('file loading', loading);

  const afterUpload = useCallback(
    (uploaded) => {
      if (toBase64 === true && uploaded?.length === 1) {
        const reader = new FileReader();
        reader.readAsDataURL(uploaded[0]);
        reader.onload = () => {
          onUploadComplete({
            name: uploaded[0].name,
            base64: reader.result.split(',')[1],
          });
        };
        reader.onerror = (error) => {
          console.error('Error: ', error);
        };
      } else if (multiple) {
        onUploadComplete(uploaded);
      } else {
        onUploadComplete(uploaded[0]);
      }
    },
    [onUploadComplete, toBase64, multiple],
  );

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    multiple,
    onDrop: (val) => {
      setLoading(true);
      if (disabled) {
        return;
      }
      afterUpload(val);
    },

    validator: (file) => {
      if (file.size > limitFileSize) {
        notification.error(
          t('file.size', {
            fileName: file.name,
            limitSize: Math.ceil(limitFileSize / 1024 ** 2),
          }),
        );
      }
    },

    maxSize: limitFileSize,
    accept,
  });

  return (
    <div style={{ display: 'flex', gap: '20px' }}>
      <div
        {...getRootProps({
          onClick: (event) => event.stopPropagation(),
        })}
        className={`file__uploader-drop ${isDragActive ? 'drop-zone' : ''}`}
        style={{ outline: 0 }}
      >
        <div className="drop-zone__message" style={{ marginBottom: '5px' }}>
          {translate('drop')}
        </div>
        <input {...getInputProps()} />
        <Button
          disabled={disabled}
          key="fileButton"
          icon={icon}
          type="primary"
          onClick={open}
          style={{ float: 'right', marginLeft: 'auto' }}
          block={block}
        >
          {title}
        </Button>
        {children}
      </div>
    </div>
  );
};

export default FileUploader;

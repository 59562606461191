import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Form, Modal } from 'antd';
import AddEntityButton from '../../buttons/AddEntityButton';
import FileUploader from '../../utils/FileUploader';
import ImageUploader from '../../utils/ImageUploader';
import {
  create,
  findOne,
  update,
} from '../../../servicies/mediafiles/magazine';
import { downloadFiles, getBlobFile } from '../../../servicies/files';
import FormInput from '../../form/FormInput';
import Icon from '../../utils/Icon';
import { useTranslate } from '../../../hooks/useTranslate';
import { tagRules } from '../../form/rules';
import MagazineEditHistoryList from '../../magazine/MagazineEditHistoryList';

const AddMagazineModal = ({
  magazineId = null,
  onSave = () => {},
  onError = () => {},
  onFinally = () => {},
  onClose = () => {},
}) => {
  const { translate, t } = useTranslate('pages.admin.magazine');
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState([]);
  const [imageUrl, setImageUrl] = useState(null);
  const [image, setImage] = useState();
  const [magazine, setMagazine] = useState(null);

  const action = useMemo(() => (magazineId ? update : create), [magazineId]);

  const handleChangeHistory = (h) => {
    console.log(h, 'history');
  };
  const handleClose = useCallback(() => {
    setOpen(false);
    setFile([]);
    if (imageUrl) {
      URL.revokeObjectURL(imageUrl);
      setImageUrl(null);
    }
    setImage(null);
    setMagazine(null);
    form.resetFields();
    onClose();
  }, [form, imageUrl, onClose]);

  useEffect(() => {
    if (magazineId) {
      setOpen(true);
      findOne(magazineId).then(setMagazine);
    } else {
      setMagazine(null);
    }
  }, [magazineId]);

  useEffect(() => {
    if (magazine?.title) {
      form.setFieldsValue({ title: magazine?.title });
    }
    if (magazine?.tags) {
      form.setFieldsValue({ tags: magazine.tags.join(' ') });
    }
    if (magazine?.imageId) {
      getBlobFile(magazine?.imageId).then((res) => {
        const url = URL.createObjectURL(res);
        setImageUrl(url);
      });
    }
  }, [form, magazine?.imageId, magazine?.title, magazine?.tags]);

  const onSubmit = useCallback(() => {
    const title = form.getFieldValue('title');
    const history = form.getFieldValue('history');
    const tags = form.getFieldValue('tags')?.split(' ');
    if (!magazineId && (!image || !title || !file)) {
      // TODO: Denis nada translated notification
      return;
    }

    const obj = {
      id: magazineId,
      tags,
      title,
      base64PDF: file.base64,
      base64IMG: image,
      history,
    };

    action(obj)
      .then(() => {
        onSave();
        handleClose();
      })
      .catch(onError)
      .finally(onFinally);
  }, [
    action,
    file,
    form,
    handleClose,
    image,
    magazineId,
    onError,
    onFinally,
    onSave,
  ]);

  return (
    <div>
      <AddEntityButton
        title={translate('addMagazine')}
        onClick={() => {
          handleClose();
          setOpen(true);
        }}
        style={{ marginBottom: '20px' }}
      />
      <Modal
        centered
        open={open}
        onOk={onSubmit}
        onCancel={handleClose}
        closeIcon={<Icon name="close" />}
        width={1000}
        title={translate('addMagazine')}
        okText={translate('history.save')}
      >
        <Form form={form}>
          <div className="magazine__modal-content">
            <div className="magazine__modal-cover">
              <h5>{translate('history.addCover')}</h5>
              <ImageUploader
                initial={imageUrl}
                onImageUpload={setImage}
                onImageClear={() => setImage(null)}
                aspectRatio={1 / 1.4142}
              />
            </div>
            <div>
              <FormInput name="title" translate={translate} />
              <FormInput
                name="tags"
                translate={translate}
                placeholder="#tag"
                rules={tagRules(t)}
              />
              {magazine?.pdfId ? (
                <Button
                  block
                  className="magazine__download-pdf"
                  onClick={() => downloadFiles(magazine?.pdfId)}
                >
                  Download Existing PDF
                </Button>
              ) : null}

              <FileUploader
                title={
                  magazineId ? translate('changePDF') : translate('addPDF')
                }
                accept={{ 'application/pdf': ['.pdf'] }}
                multiple={false}
                onUploadComplete={setFile}
                toBase64
                icon={magazineId ? null : <Icon name="plus" />}
                block="true"
              />
              <div>{file?.name}</div>
            </div>
            <Form.Item noStyle name="history">
              <MagazineEditHistoryList
                initial={magazine?.history}
                onChange={handleChangeHistory}
              />
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default AddMagazineModal;

import React, { useCallback } from 'react';
import { Button } from 'antd';
import FilesImage from '../utils/FilesImage';
import Icon from '../utils/Icon';

const MagazinePreview = ({ imageId, onClick = () => {} }) => {
  const handleClick = useCallback(() => {
    if (onClick && typeof onClick === 'function') {
      onClick();
    }
  }, [onClick]);

  return (
    <div className="magazine__preview">
      <div className="magazine__preview-overlay">
        <Button
          className="magazine__preview-overlay--btn"
          type="link"
          icon={<Icon name="eyeOpen" />}
          onClick={handleClick}
        />
      </div>
      <button
        className="magazine__preview-btn"
        type="button"
        onClick={handleClick}
      >
        <FilesImage fileId={imageId} />
      </button>
    </div>
  );
};

export default MagazinePreview;

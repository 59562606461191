import React, { useEffect, useState } from 'react';
import { Skeleton } from 'antd';
import { getBlobFile } from '../../servicies/files';

const FilesImage = ({ fileId }) => {
  const [imageUrl, setImageUrl] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (fileId) {
      setLoading(true);
      getBlobFile(fileId)
        .then((res) => {
          const url = URL.createObjectURL(res);
          setImageUrl(url);
        })
        .finally(() => setLoading(false));
    }
    return () => {
      setImageUrl((prev) => {
        if (prev) {
          URL.revokeObjectURL(prev);
        }
        return null;
      });
    };
  }, [fileId]);

  return loading ? (
    <Skeleton.Image active />
  ) : (
    <img src={imageUrl} alt="Magazine" />
  );
};

export default FilesImage;

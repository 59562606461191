import React, { useEffect, useState } from 'react';
import { Button, Tooltip } from 'antd';
import Icon from '../utils/Icon';
import MagazineEditHistoryItem from './MagazineEditHistoryItem';
import { useTranslate } from '../../hooks/useTranslate';

const MagazineHistory = ({
  dataSet = [],
  onPageClick = () => {},
  maximize,
}) => {
  const [magazineTooltip, setMagazineTooltip] = useState(true);
  const { translate } = useTranslate('pages.magazine');

  const handleTooltip = () => {
    setMagazineTooltip((prev) => !prev);
  };

  useEffect(() => {
    if (maximize) {
      setMagazineTooltip(false);
    } else {
      setMagazineTooltip(true);
    }
  }, [maximize]);

  return (
    <div className="magazine__history">
      <Tooltip title={translate('history')}>
        <Button
          className="magazine__history-btn"
          onClick={handleTooltip}
          type="link"
          icon={<Icon name="book" />}
        />
        <div
          className={`magazine__history-tooltip ${magazineTooltip ? 'active' : ''}`}
        >
          <MagazineEditHistoryItem
            history={dataSet}
            onPageClick={onPageClick}
          />
          {/* TODO: if authorId !== null then render userName as button and redirect to author profile */}
          {/* TODO: add function onUserClick */}
          {/* TODO: also render user avatar near userName */}
        </div>
      </Tooltip>
    </div>
  );
};

export default MagazineHistory;

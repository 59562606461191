import React, { useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'antd';
import Icon from '../utils/Icon';
import FormInput from '../form/FormInput';
import FormArea from '../form/FormArea';
import { useTranslate } from '../../hooks/useTranslate';

const MagazineEditHistoryModal = ({ onAdd = () => {} }) => {
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const { translate } = useTranslate('pages.admin.magazine.history');

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const handleSave = () => {
    // TODO: add tempId to be abble localy remove
    onAdd(form.getFieldsValue());
    handleOpen();
    form.resetFields();
  };

  return (
    <>
      <Button
        icon={<Icon name="plus" />}
        className="magazine__modal-history--btn"
        type="primary"
        onClick={handleOpen}
      >
        {translate('addHistory')}
      </Button>
      <Modal
        onCancel={handleOpen}
        open={open}
        centered
        closeIcon={<Icon name="close" />}
        title={translate('addHistory')}
        onOk={handleSave}
      >
        <Form form={form}>
          <FormInput name="userName" translate={translate} />
          <Row gutter={15}>
            <Col span={12}>
              <FormInput required name="fromPage" translate={translate} />
            </Col>
            <Col span={12}>
              <FormInput required name="toPage" translate={translate} />
            </Col>
          </Row>
          <FormArea
            name="userDescription"
            translate={translate}
            maxLength={255}
          />
          <FormArea
            required
            name="captionDescription"
            translate={translate}
            maxLength={255}
          />
        </Form>
      </Modal>
    </>
  );
};

export default MagazineEditHistoryModal;

import React, { useMemo, useState } from 'react';
import { Table } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import Container from '../../../utils/layout/Container';
import AddEntityButton from '../../buttons/AddEntityButton';
import { findAll, remove } from '../../../servicies/users/roles';
import { ADMIN_PERMS, AuthData, hasPermission } from '../../auth/AuthWrapper';
import DeleteItemIcon from '../../buttons/icons/DeleteItemIcon';
import EditItemIcon from '../../buttons/icons/EditItemIcon';
import { useTranslate } from '../../../hooks/useTranslate';
import useDatasource from '../../../hooks/useDatasource';

import Column from '../../utils/Columns';
import EditRoleModal from './EditRoleModal';

const RolesList = () => {
  const { translate, t } = useTranslate('admin.role');
  const { userContext } = AuthData();
  const { user } = userContext;
  const { permissions } = user;

  const [selectedRoleId, setSelectedRoleId] = useState();

  const { loading, pagination, content, handleChange, reload } =
    useDatasource(findAll);

  const columns = useMemo(
    () => [
      Column.text('name', translate, {
        width: 300,
        filter: true,
      }),
      Column.text('description', translate, {
        width: 300,
        filter: true,
      }),
      Column.date('createdAt', translate, {
        width: 150,
      }),
      Column.text('createdBy', translate, {
        width: 150,
      }),
      Column.date('updatedAt', translate, {
        width: 150,
      }),
      Column.text('updatedBy', translate, {
        width: 150,
      }),
      Column.bool('deleted', translate, {
        width: 150,
        filter: true,
        inverted: true,
        labels: [
          t('entity.deleted.all'),
          t('entity.deleted.true'),
          t('entity.deleted.false'),
          t('entity.deleted.unknown'),
        ],
      }),
      Column.actions(
        t,
        (record) =>
          hasPermission(permissions, ADMIN_PERMS.ADMIN_ROLES_MANAGEMENT) && (
            <span style={{ textAlign: 'right' }}>
              <EditItemIcon onClick={() => setSelectedRoleId(record.id)} />
              <DeleteItemIcon
                key={`delete${record.id}`}
                title={translate('_delete', record)}
                message={translate('_deleted', record)}
                item={record}
                onClick={remove}
                reload={reload}
                isPromise
              />
            </span>
          ),
      ),
    ],
    [translate, t, permissions, reload, setSelectedRoleId],
  );

  return (
    <div className="roles">
      <Container>
        <div className="roles__content">
          <PageHeader
            title={translate('_plural')}
            extra={[
              <AddEntityButton
                key="new"
                entity={translate('_singular')}
                onClick={() => setSelectedRoleId('new')}
              />,
            ]}
          />
          <Table
            columns={columns}
            rowKey="id"
            loading={loading}
            pagination={pagination}
            dataSource={content}
            onChange={handleChange}
          />
        </div>
        {selectedRoleId ? (
          <EditRoleModal
            roleId={selectedRoleId}
            onClose={() => setSelectedRoleId(null)}
            onSave={reload}
          />
        ) : null}
      </Container>
    </div>
  );
};

export default RolesList;

import React from 'react';
import AuthWrapper from './components/auth/AuthWrapper';
import Default from './components/Default';
import RouteRenderer from './components/nav/NavigationRender';

const App = () => {
  return (
    <div className="App">
      <AuthWrapper>
        <Default>
          <RouteRenderer />
        </Default>
      </AuthWrapper>
    </div>
  );
};

export default App;

import React, { useEffect, useState } from 'react';
import AddEntityButton from '../../../components/buttons/AddEntityButton';
import ModalTaxBuilder from '../../../components/taxonomies/ModalTaxBuilder';
import { INPUT } from '../../../utils/defaults/fields';
import {
  create,
  findOne,
  update,
} from '../../../servicies/taxonomies/countries';
import { useEdit } from '../../../hooks/useEdit';

const initial = {};
const fields = [
  {
    name: 'name',
    type: INPUT,
  },
  {
    name: 'alpha2',
    type: INPUT,
  },
  {
    name: 'alpha3',
    type: INPUT,
  },
];

const Country = ({ id = 'new', translate }) => {
  const [modalVisible, setModalVisible] = useState(false);

  const [entity, handleSubmit, errors, loading, reload] = useEdit({
    initial,
    existent: findOne,
    onCreate: create,
    onUpdate: update,
    isNew: id === 'new',
    id,
  });

  useEffect(() => {
    if (id) {
      reload();
    }
  }, [id, reload]);

  return (
    <>
      <AddEntityButton onClick={() => setModalVisible(true)} />
      <ModalTaxBuilder
        open={modalVisible}
        fields={fields}
        title="title"
        entity={entity}
        onSave={handleSubmit}
        onCancel={() => setModalVisible(false)}
        translate={translate}
        errors={errors}
        loading={loading}
      />
    </>
  );
};

export default Country;
